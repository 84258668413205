<template>
  <div class="contact-page page">
    <b-container fluid>
      <b-row >
        <b-col lg="6" cols="12" class="contact-page__info" v-inview="'fade-from-left'">
          <div class="contact-page__info--content">
            <div class="contact-page__info--content-table">
              <table>
                <tr>
                  <td colspan="2">
                    <h3>HEINEKEN Hungária Sörgyárak Zrt.</h3>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <img
                        src="@/assets/images/icons/mailbox.svg"
                        alt=""
                      /><strong>Postacím</strong>
                    </p>
                  </td>
                  <td>
                    <p>9400 Sopron, Vándor Sándor utca 1.</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <img src="@/assets/images/icons/phone.svg" alt="" /><strong>Telefon</strong>
                    </p>
                  </td>
                  <td>
                    <p>06 (99) 516-200</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <img src="@/assets/images/icons/fax.svg" alt="" /><strong
                        >Fax</strong
                      >
                    </p>
                  </td>
                  <td>
                    <p>06 (99) 516-111</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <img
                        src="@/assets/images/icons/search.svg"
                        alt=""
                      /><strong>Website</strong>
                    </p>
                  </td>
                  <td>
                    <a href="https://www.heinekenhungaria.hu/" target="_blank" style="color:white; text-decoration:none">heinekenhungaria.hu</a>
                  </td>
                </tr>
              </table>

              <table>
                <tr>
                  <td class="underline-contact"></td>
                </tr>
              </table>

              <table>
                <tr>
                  <td colspan="2">
                    <h3>Kereskedelmi Központ</h3>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <img
                        src="@/assets/images/icons/mailbox.svg"
                        alt=""
                      /><strong>Postacím</strong>
                    </p>
                  </td>
                  <td>
                    <p>1068 Budapest, Dózsa György út 84/A.</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <img
                        src="@/assets/images/icons/phone.svg"
                        alt=""
                      /><strong>Telefon</strong>
                    </p>
                  </td>
                  <td>
                    <p>06 (1) 437-2155</p>
                  </td>
                </tr>
              </table>

              <table>
                <tr>
                  <td class="underline-contact"></td>
                </tr>
              </table>

              <table>
                <tr>
                  <td colspan="2">
                    <h3>Sörgyárlátogatás</h3>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <img
                        src="@/assets/images/icons/Globe.svg" /><strong>Web</strong>
                    </p>
                  </td>
                  <td>
                    <a href="https://www.heinekenhungaria.hu/sorgyartura" target="_blank" style="color:white; text-decoration:none">heinekenhungaria.hu/sorgyartura</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <img
                        src="@/assets/images/icons/envelope.svg"
                        alt=""
                      /><strong>E-mail</strong>
                    </p>
                  </td>
                  <td>
                    <a href="mailto:sorgyartura@heineken.hu" style="color:white; text-decoration:none">sorgyartura@heineken.hu</a>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </b-col>

        <b-col lg="6" cols="12" class="contact-page__question-form" v-inview="'fade-from-right'">
          <h1>Kérdésed van? Üzenj nekünk!</h1>
          <h3>Az alábbi mezők kitöltésével küldheted el kérdésed.</h3>
          <div class="contact-page__question-form--group" v-if="!successEmailSending">
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
              <b-form novalidate @submit.stop.prevent="handleSubmit(onSubmit)">
                <validation-provider
                  name="Név"
                  rules="required|min:3"
                  v-slot="validationContext"
                >
                  <b-form-input
                    id="input-live-1"
                    v-model="form.name"
                    :required="true"
                    :state="getValidationState(validationContext)"
                    placeholder="név"
                  >
                  </b-form-input>
                  <b-form-invalid-feedback id="input-live-1-feedback"
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
                <validation-provider
                  name="Email"
                  rules="required|email"
                  v-slot="validationContext"
                >
                  <b-form-input
                    id="input-live-2"
                    v-model="form.email"
                    :required="true"
                    :state="getValidationState(validationContext)"
                    placeholder="E-mail cím"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-live-2-feedback"
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
                <validation-provider
                  name="Message"
                  rules="required|min:3"
                  v-slot="validationContext"
                >
                  <b-form-textarea
                    v-model="form.message"
                    id="textarea"
                    placeholder="Üzenet"
                    rows="3"                    
                    max-rows="6"
                    :state="getValidationState(validationContext)"
                  ></b-form-textarea>
                  <b-form-invalid-feedback id="textarea-feedback"
                    >{{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
                <div class="contact-page__question-form--group-checkbox-btn">
                  <validation-provider
                    name="Checkbox"
                    rules="required|acceptConditions"
                    v-slot="validationContext"
                  >
                    <b-form-checkbox
                      v-model="form.privacyPolicyAccepted"
                      :required="true"
                      :state="getValidationState(validationContext)"
                      id="checkbox-1"
                      name="checkbox-1"
                      value="accepted"
                      unchecked-value="not_accepted"
                    >
                      Elfogadom az
                      <!-- <a :href="base_url + 'content/informacio/Adatvedelmi_Nyilatkozat.pdf?' + TimeStamp" target="_blank" class="contact-txt-color">
                        Adatvédelmi nyilatkozatot.
                      </a> -->
                       <a href="https://www.heineken.com/hu/hu/adatvedelmi-tajekoztato" target="_blank" >
                          Adatvédelmi nyilatkozatot.
                      </a>
                    </b-form-checkbox>
                  </validation-provider>
                  <button type="submit" class="btn btn--gold btn--with-arrow">
                    <span>Üzenet küldése</span>
                  </button>
                  
                </div>
                <p v-if="sendingError" class="not-send-error">Valami mellément. Próbáld meg újra!</p>                
              </b-form>
            </validation-observer>
          </div>
          <div v-if="successEmailSending">
            <!-- SUCCESS EMAIL SEND -->
            <p class="success-msg">Köszi, megkaptuk. Hamarosan jelentkezünk.</p>
            <button class="btn btn--gold btn--with-arrow" @click="newMsg()">
                 <span>Új üzenetet írok</span>
            </button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import apiHandlerMI from "@/mixins/apiHandlerMI.js";
import  {senderEmail}  from '@/setup/init.js'

//validation
import { required, email } from "vee-validate/dist/rules";
import {extend, setInteractionMode, ValidationObserver, ValidationProvider} from "vee-validate";

// Install VeeValidate components globally
setInteractionMode('eager')

extend("required", {
	...required,
	message: "Kötelező megadni.",
});
extend("email", {
	...email,
	message: "Nem megfelelő e-mail cím formátum",
});
extend("min", {
	validate(value, args) {
		return value.length >= args.length;
	},
	params: ["length"],
	message: "Nem elegendő karakterszám",
});

extend("acceptConditions", {
  validate(value) {
    if (value == 'accepted') {
      return true;
    } else {
      return false;
    }
  },
  message: "A Játékszabályzatot kötelező elfogadnia. ",
});

export default {
  mixins: [apiHandlerMI],
  components:{
    ValidationObserver,
    ValidationProvider
  },
  methods: {
    getValidationState({ validated, valid = null }) {
      return validated ? valid : null;
    },
    onSubmit() {
      const _this = this;
      // console.log("Form submitted: ", _this.form);
      _this.post("contact",
        {
          name: _this.form.name,
          email: _this.form.email,
          description: _this.form.message,
          condition: _this.form.privacyPolicyAccepted            
        },
        {},
        () => {

          _this.post(
            "emailsender/1/1",
            {
              to: senderEmail,
              data_replace: {
                name: _this.form.name,
                email: _this.form.email,
                description: _this.form.message                
              },
            },
            {},
            () => {
              //sucess email send
              _this.sendingError = false;
              _this.successEmailSending = true

              _this.setGTM('sikeres')

               //set the default state
              _this.form.name = null
              _this.form.email = null
              _this.form.message = null
              _this.form.privacyPolicyAccepted = null
              
            },
            () => {
              //not sucess - proplem width the cc email
              _this.sendingError = true;
              _this.successEmailSending = false

              _this.setGTM('sikertelen')
             
            }
          );
        },
        () => {
          //not success - problem with the contact table
          _this.sendingError = true;
          _this.successEmailSending = false

          _this.setGTM('sikertelen')
        }
      );
    },
    newMsg(){
      this.sendingError = false;
      this.successEmailSending = false
    },
    setGTM(succ){
        
        this.GTtrackGA4({
            'event' : 'event',
            'category': 'kapcsolat',
            'action': 'uzenetkuldes',
            'label': succ,
            'page_type': 'kapcsolat',
            'form': 'kapcsolat',            
            'success':succ,
            'action_type': 'form_kitoltes'
        })
    }
  },
  props: {
    imageSrc: String,
  },
  data() {
    return {      
      CONFIG: {
        apiUrl: process.env.VUE_APP_API_URL,
      },
      sendingError: false,
      successEmailSending: false,
      form: {
        name: null,
        email: null,
        message: null,
        privacyPolicyAccepted: null,
      },
    };
  },
  mounted(){
    this.setMetaTags({
        title: 'Soproni - Kapcsolat', 
        desc: null, 
        keywords: null, 
        ogType: 'website', 
        ogTitle: 'Soproni - Kapcsolat',                         
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc:null
    })
  }
};
</script>
         
